import "core-js/modules/es.array.push.js";
import { navApi, webApi, indexApi } from '../../request';
export default {
  data() {
    return {
      content: "",
      showNav: true,
      navBgc: false,
      scrollTop: 0,
      acolor: "black",
      navList: [],
      showScroll: false,
      web: [],
      href: "",
      show: false,
      widthChange: false,
      screenWidth: document.body.clientWidth
    };
  },
  async created() {
    document.title = '隐私政策';
    let res = await navApi();
    this.navList = res.data.data.reverse();
    let web = await webApi({
      web_seting_id: 1,
      type: 'privacy'
    });
    this.web.push(web.data);
    let res2 = await indexApi({
      type: 'company_profile'
    });
    this.href = res2.data.data[0].href;
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    // 
    if (this.screenWidth <= 974 && this.screenWidth > 0) {
      this.widthChange = true;
      this.width = '100%';
      this.showScroll = false;
    } else if (this.screenWidth <= 470) {
      this.widthChangeAgain = true;
      this.width = '100%';
      this.showScroll = false;
    } else {
      this.widthChange = false;
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器

      if (this.screenWidth <= 974 && this.screenWidth > 470) {
        this.widthChange = true;
        this.width = '100%';
        this.showScroll = false;
      } else if (this.screenWidth <= 470) {
        this.width = '100%';
        this.showScroll = false;
        this.widthChange = false;
      }
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false;
        }, 50);
      }
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    godownload(h) {
      window.location.href = h;
    },
    abouthref() {
      window.location.href = this.href;
    },
    goIndex() {
      this.$router.push('/index');
    },
    goxieyi() {
      this.$router.push('/serviceAgreement');
      window.scrollTo(0, 0);
    },
    scrollup() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      });
    },
    handleScroll(e) {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 80) {
        this.navBgc = true;
        this.showNav = false;
        this.showScroll = true;
      } else {
        this.navBgc = false;
        this.showNav = true;
        this.showScroll = false;
      }
    }
  }
};