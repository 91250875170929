var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "details"
  }, _vm._l(_vm.web, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "ww"
    }, [_c('div', {
      staticClass: "title"
    }, [_c('h3', [_vm._v(_vm._s(item.privacy_title))])]), _c('div', {
      staticClass: "content",
      domProps: {
        "innerHTML": _vm._s(item.privacy_agreement)
      }
    })]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };